import { useEffect, useState, useRef } from 'react';

import { Drawer, Form, Input, Button, Checkbox, Row } from 'antd';

import REQUESTS from '../../../api/requests';

import ErrorMessage from '../../../components/ErrorMessage';

import { LoadScript, Autocomplete } from '@react-google-maps/api'; //module for Places API

const libraries = ['places']; // Include 'places' for Places API

export default function UserDrawer({ open, current, onClose, getData }) {

    const [form] = Form.useForm();

    const [message, setMessage] = useState({ type: false, text: "" });
    const [country, setCountry] = useState("");

    const autocompleteRef = useRef(null);

    const onFinish = (values) => {

        setMessage({ type: false, text: "" });

        const {
            password,
            first_name,
            last_name,
            phone_number,
            invoice_by_email,
            invoice_by_paper,
            invoice_by_sms,
            email,
            address } = values

        const body = {
            first_name,
            last_name,
            address,
            country,
            phone_number: "+" + phone_number,
            invoice_by_email,
            invoice_by_paper,
            invoice_by_sms,
        };

        if (email) {
            body.email = email;
        }

        if (password) {
            body.password = password;
        }

        const callback = (resp) => {
            onClose();
            getData();
        };

        const errorCallback = (err) => {
            setMessage({ type: false, text: err });
        };

        if (current) {
            body['id'] = current.id;
            REQUESTS.USER.UPDATE(body, callback, errorCallback);
        } else {
            REQUESTS.USER.CREATE(body, callback, errorCallback);
        }
    }

    const handlePlaceChanged = () => {
        const place = autocompleteRef.current.getPlace();

        if (place && place.formatted_address) {
            form.setFieldsValue({ address: place.formatted_address });
            place.address_components.forEach((component) => {
                component.types.forEach((type) => {
                    if (type === "country") {
                        setCountry(component.long_name);
                    }
                })
            })
        }
    }

    useEffect(() => {
        if (!current) {
            form.resetFields();
            return;
        }

        form.setFields([
            { name: "first_name", value: current.first_name },
            { name: "last_name", value: current.last_name },
            { name: "email", value: current.email },
            { name: "address", value: current.address },
            { name: "invoice_by_email", value: !!current.invoice_by_email },
            { name: "invoice_by_paper", value: !!current.invoice_by_paper },
            { name: "invoice_by_sms", value: !!current.invoice_by_sms },
        ]);

        setCountry(current.country);

        if (current.phone_number) {
            if (current.phone_number.includes("+")) {
                form.setFieldsValue({ phone_number: current.phone_number.substring(1) });
            } else {
                form.setFieldsValue({ phone_number: current.phone_number });
            };
        }
    }, [open, current])

    useEffect(() => {
        if (!open) {
            form.resetFields();
        }
    }, [open])

    return (
        <Drawer
            title={current ? "Edit User" : "Add User"}
            open={open}
            onClose={onClose}
            destroyOnClose={true}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                onValuesChange={(changedValues, allValues) => {
                    setMessage({ type: false, text: "" })
                }}
                initialValues={{
                    invoice_by_email: false,
                    invoice_by_paper: false,
                    invoice_by_sms: false
                }}
            >
                <Form.Item
                    label="First Name"
                    name='first_name'
                    rules={[
                        {
                            required: true,
                            message: "Please input your first name!",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Last Name"
                    name='last_name'
                    rules={[
                        {
                            required: true,
                            message: "Please input your last name!",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name='email'
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="phone_number"
                    label="Phone"
                    rules={[
                        {
                            required: true,
                            message: "Please input your phone number!",
                        },
                    ]}
                >
                    <Input
                        prefix="+"
                        onChange={(e) => {
                            const value = e.target.value;
                            const formattedValue = value.replace(/\s+/g, '').replace(/[^0-9]/g, '');
                            form.setFieldsValue({ phone_number: formattedValue });
                        }} />
                </Form.Item>

                <LoadScript
                    googleMapsApiKey={"AIzaSyAWJeHF0FmLaKkpyu8a0tZLAMRyzholBlQ"}
                    libraries={libraries}
                >
                    <Autocomplete
                        onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                        onPlaceChanged={handlePlaceChanged}
                    >
                        <Form.Item
                            name="address"
                            label="Address"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input your address!",
                                },
                            ]}
                        >
                            <Input placeholder='' />
                        </Form.Item>
                    </Autocomplete>
                </LoadScript>
                <Form.Item
                    name="password"
                    label="Password"
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="invoice_by_paper"
                    valuePropName="checked"
                    wrapperCol={{
                        span: 16,
                    }}
                    style={{ marginBottom: 0 }}
                >
                    <Checkbox>Invoice By Paper</Checkbox>
                </Form.Item>

                <Form.Item
                    name="invoice_by_email"
                    valuePropName="checked"
                    wrapperCol={{
                        span: 16,
                    }}
                    style={{ marginBottom: 0 }}
                >
                    <Checkbox>Invoice By Email</Checkbox>
                </Form.Item>
                <Form.Item
                    name="invoice_by_sms"
                    valuePropName="checked"
                    wrapperCol={{
                        span: 16,
                    }}
                >
                    <Checkbox >Invoice By SMS</Checkbox>
                </Form.Item>

                <ErrorMessage message={message} />

                <Row justify="center">
                    <Form.Item >
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </Drawer >
    )
}

import { useEffect, useState } from "react";

import { Button, Row, Table, Tag, notification } from "antd";
import {
    PlusOutlined,
    DeleteOutlined,
    CreditCardOutlined,
    FileTextOutlined,
    MailOutlined,
    MessageOutlined,
    EyeOutlined
} from "@ant-design/icons";

import REQUESTS from "../../api/requests";
import getMyDate from "../../components/getMyDate";
import SearchByDate from "../../components/SearchByDate";
import showPropsConfirm from "../../components/showPropsConfirm";

import { getColumnSearchProps } from "../../utils";
import InvoicesDrawer from "./components/InvoicesDrawer";
import TableButtons from "../../components/TableButtons";
import PaymentPopup from "./components/PaymentPopup";
import InvoiceStatistics from "./components/InvoiceStatistics";

export default function Invoices() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [sort, setSort] = useState(['createdAt', 'DESC']);
    const [search, setSearch] = useState({ "status": ["unpaid", "half_paid"] });
    const [date, setDate] = useState('');
    const [sendDate, setSendDate] = useState('');
    const [open, setOpen] = useState(false);
    const [current, setCurrent] = useState(1);
    const [paymentPopup, setPaymentPopup] = useState(false);

    const columns = [
        {
            title: "#",
            key: "id",
            dataIndex: "id",
            align: "center"
        },
        {
            title: "Invoice Id",
            key: "invoice_id",
            dataIndex: "invoice_id",
            align: "center",
            ...getColumnSearchProps("invoice_id"),
        },
        {
            title: "Title",
            key: "title",
            dataIndex: "title",
            align: "center",
            ...getColumnSearchProps("title"),
        },
        {
            title: "Client",
            key: "email",
            dataIndex: "email",
            align: "center",
            render: (text, record) => record?.user?.email || "N/A"
        },
        {
            title: "Total",
            key: "amount",
            dataIndex: "amount",
            align: "center",
            sorter: true,
        },
        {
            title: "Pay amount",
            key: "pay_amount",
            dataIndex: "pay_amount",
            align: "center",
            sorter: true,
        },
        {
            title: "Status",
            key: "status",
            dataIndex: "status",
            align: "center",
            filters: [
                { text: "Paid", value: "paid" },
                { text: "Unpaid", value: "unpaid" },
                { text: "Half paid", value: "half_paid" }
            ],
            defaultFilteredValue: ["unpaid", "half_paid"],
            render: (text) => {
                let type = text ? text[0].toUpperCase() + text.slice(1) : text;
                switch (text) {
                    case "unpaid": return <Tag color="red">{type}</Tag>;
                    case "paid": return <Tag color="green">{type}</Tag>;
                    case "half_paid": return <Tag color="orange">{type.replace("_", " ")}</Tag>;
                }
            }
        },
        {
            title: "Send Date",
            key: "send_date",
            dataIndex: "send_date",
            align: "center",
            sorter: true,
            ...SearchByDate(setSendDate),
            render: (text) => text ? getMyDate(text) : "N/A"
        },
        {
            title: "Created Date",
            key: "createdAt",
            dataIndex: "createdAt",
            align: "center",
            sorter: true,
            ...SearchByDate(setDate),
            render: (text) => text ? getMyDate(text) : "N/A"
        },
        {
            title: "Actions",
            key: "Actions",
            align: "center",
            dataIndex: "Actions",
            render: (text, record) => {
                const buttons = [
                    {
                        key: "pay",
                        text: "Pay",
                        icon: <CreditCardOutlined style={{ fontSize: 16 }} />,
                        disabled: record.status == "paid"
                    },
                    {
                        key: "view",
                        text: "View",
                        icon: <EyeOutlined style={{ fontSize: 16 }} />
                    },
                    {
                        key: "SEND_EMAIL",
                        text: "Send Email",
                        icon: <MailOutlined style={{ fontSize: 16 }} />
                    },
                    {
                        key: "SEND_SMS",
                        text: "Send SMS",
                        icon: <MessageOutlined style={{ fontSize: 16 }} />
                    },
                    {
                        key: "SEND_PAPER",
                        text: "Send Paper",
                        icon: <FileTextOutlined style={{ fontSize: 16 }} />
                    },
                    {
                        key: "delete",
                        text: "Delete",
                        icon: <DeleteOutlined style={{ fontSize: 16 }} />
                    },
                ];

                return <TableButtons
                    handleMenuClick={(key) => handleActions(key, record)}
                    buttons={buttons}
                />
            }
        },
    ];

    const deleteInvoice = (id) => {
        REQUESTS.INVOICES.DELETE({ id }, (resp) => {
            getData();
        });
    }

    const sendInvoice = (type, record) => {
        const body = {
            id: record.id,
        };

        REQUESTS.INVOICES[type](body, (resp) => {
            notification.success({
                message: "Success",
                description: resp
            })
        }, (err) => {
            notification.error({
                message: "Error",
                description: err
            })
        })
    }

    const handleActions = (action, record) => {
        switch (action.key) {
            case "delete":
                showPropsConfirm(record.id, deleteInvoice, false, "DELETE", "red");
                break;
            case "SEND_EMAIL":
                sendInvoice("SEND_EMAIL", record);
                break;
            case "SEND_SMS":
                sendInvoice("SEND_SMS", record);
                break;
            case "SEND_PAPER":
                sendInvoice("SEND_PAPER", record);
                break;
            case "pay":
                setCurrent(record);
                setPaymentPopup(true);
                break;
            case "view":
                window.open(record.pdf_url, '_blank');
                break;
            default:
                break;
        }
    }

    const getData = () => {
        const query = {
            limit: limit,
            page: currentPage,
            sort,
        }

        const callback = (resp) => {
            setData(resp.rows);
            setTotal(resp.count);
        };

        if (search?.invoice_id) {
            query['search'] = {};
            query.search['invoice_id'] = search.invoice_id[0];
        }

        if (search?.title) {
            query['search'] = {};
            query.search['title'] = search.title[0];
        }

        if (search?.status) {
            query['filter_in'] = {
                status: search.status
            };
        }

        let dateData = [
            { name: "createdAt", date: date },
            { name: "send_date", date: sendDate },
        ]

        for (let i = 0; i < dateData.length; i++) {
            if (dateData[i].date && dateData[i].date[0]) {
                query.between = {
                    [dateData[i].name]: {
                        from: dateData[i].date[0] + " 00:00:00",
                        to: dateData[i].date[1] + " 23:59:59",
                    },
                };
            }
        }

        REQUESTS.INVOICES.GET({ query: JSON.stringify(query) }, callback)
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setCurrentPage(pagination.current);
        setLimit(pagination.pageSize);

        if (sorter.field) {
            setSort([sorter.field, sorter.order == "ascend" ? "ASC" : "DESC"]);
        } else {
            setSort(['createdAt', 'DESC']);
        }

        setSearch(filters);
    }

    useEffect(() => {
        let timeout = setTimeout(getData, 500);

        return () => clearTimeout(timeout);

    }, [currentPage, limit, date, sendDate, search, sort]);

    return (
        <div>
            <InvoiceStatistics />

            <Row justify="space-between" align='middle'>
                <h1 className="page-title">Invoices</h1>
                <Button type="primary" onClick={() => { setOpen(true) }}><PlusOutlined /></Button>
            </Row>
            <Table
                rowKey="id"
                columns={columns}
                dataSource={data}
                scroll={{ x: "max-content" }}
                loading={loading}
                onChange={handleTableChange}
                pagination={{
                    position: ["bottomCenter"],
                    current: currentPage,
                    pageSize: limit,
                    total: total,
                    showSizeChanger: true,
                }}
                size="small"
            />
            <InvoicesDrawer
                open={open}
                onClose={() => { setOpen(false) }}
                getData={getData}
            />
            <PaymentPopup
                open={paymentPopup}
                onCancel={() => setPaymentPopup(false)}
                current={current}
                getData={getData}
            />
        </div>
    )
}

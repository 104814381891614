
import { Modal, Tabs, Tag } from 'antd';

import PayByCard from './PayByCard';
import PayByCheck from './PayByCheck';
import PayByCash from './PayByCash';

export default function PaymentPopup({ current, open, onCancel, getData }) {
    const { id } = current;

    if (!current) return null;

    return (
        <Modal
            title="Pay"
            footer={null}
            open={open}
            onCancel={onCancel}
            destroyOnClose={true}
        >
            <>
                <Tag color='#007378'><strong>Amount:</strong> {current.amount}</Tag>
                {current.status == "half_paid" && <Tag color='#f1be46'><strong>Pay amount :</strong>  {current.pay_amount}</Tag>}
            </>
            <Tabs
                defaultActiveKey="1"
                destroyInactiveTabPane={true}
                items={[
                    {
                        key: '1',
                        label: 'Pay By Card',
                        children: <PayByCard current={current} getData={getData} onCancel={onCancel} />,
                    },
                    {
                        key: '2',
                        label: 'Pay By Check',
                        children: <PayByCheck current={current} getData={getData} onCancel={onCancel} />,
                    },
                    {
                        key: '3',
                        label: 'Pay By Cash',
                        children: <PayByCash current={current} getData={getData} onCancel={onCancel} />,
                    },
                ]}
            />
        </Modal>
    )
}
